const WEGLOT_API_KEY = process.env.GATSBY_WEGLOT_API_KEY;

const script = document.createElement('script')
script.src = 'https://cdn.weglot.com/weglot.min.js'
document.body.appendChild(script)
script.addEventListener('load', () => {
  if (window.Weglot) {
    window.Weglot.initialize({
      api_key: WEGLOT_API_KEY,
      hide_switcher: true,
    })
  }
})
/*
exports.onClientEntry = () => {
  window.addEventListener('popstate', () => {
    if (window.location.hash === '') {
      window.location.replace('/');
    }
  });
};

*/